import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  FeaturedHeroComponent,
  PromoWrap,
  ImageWrap,
  FoodServicesImageWrap,
  FSHeroComponent
} from './FeaturedDetailHero.styles.js';
import PromoBox from 'components/shared/PromoBox';
import RecipePromoBox from './PromoVariants/Recipe/RecipePromo.jsx';
import ArticlePromoBox from './PromoVariants/Article/ArticlePromo.jsx';
import GeneralPromoBox from './PromoVariants/General/GeneralPromo.jsx';
import { SvgHeroWrapper } from './FeaturedDetailHero.styles.js';
import SVG from "react-inlinesvg";

const FeaturedDetailHero = props => {
  const { fields = {}, params = {}, sitecoreContext = {} } = props;
  const { viewBag = {}, route = {}, site } = sitecoreContext;
  const {
    styleOverride = ''
  } = params;
  const {
    heroType = '',
    title = {},
    longName = {},
    description = {},
    backgroundImage = {},
    heroImage = {},
    author = '',
    date = '',
    recipeHighlights = [],
  } = fields;
  const { style = '', contentBackground = '', textColor = '', backgroundColor = '', heroSvg = '' } = params;
  const promoProps = {
    heroType,
    title,
    copy: description,
    author,
    date,
    recipeHighlights,
    style,
    styleOverride,
    longName,
    viewBag,
    itemId: route?.itemId,
  };
  const imageToUse = heroImage?.value?.src || backgroundImage?.value?.src;
  const heroClass = imageToUse ? `fdh-${site.name}` : `fdh-${site.name} no-img`;
  if(site.name === 'bushs-beans-foodservices'){
    return (
        <FSHeroComponent backgroundColor={backgroundColor} className={`fs-hero shrink-on-print ${heroClass}`} data-use={imageToUse}>
          {imageToUse && <FoodServicesImageWrap backgroundImage={heroImage?.value || backgroundImage?.value} className="hide-on-print" />}
          <PromoWrap styleOverride={styleOverride} className={`promobox`}>{handlePromoType(promoProps)}</PromoWrap>
        </FSHeroComponent>
    )
  }
  if (heroSvg === 'beansongxxx') {
    return (
        <FeaturedHeroComponent backgroundColor={backgroundColor} className="shrink-on-print wsvg">
          <SvgHeroWrapper className="svg-hero-wrapper">
            <SVG src={backgroundImage?.value?.src} />
          </SvgHeroWrapper>
          <PromoWrap className="promo-wsvg">{handlePromoType(promoProps)}</PromoWrap>
        </FeaturedHeroComponent>
    )
  } else {
    return (
        <FeaturedHeroComponent backgroundColor={backgroundColor} className={`shrink-on-print ${heroClass}`} data-use={imageToUse}>
          {imageToUse && <ImageWrap backgroundImage={heroImage?.value || backgroundImage?.value} className="hide-on-print" />}
          <PromoWrap styleOverride={styleOverride} className={`promobox`}>{handlePromoType(promoProps)}</PromoWrap>
        </FeaturedHeroComponent>
    )
  }
};

const handlePromoType = props => {
  const { heroType } = props;

  switch (heroType.toLowerCase()) {
    case 'recipe':
      return <RecipePromoBox {...props} />;
    case 'article':
      return <ArticlePromoBox {...props} />;
    case 'general':
      return <GeneralPromoBox {...props} />;
    default:
      return <PromoBox {...props} />;
  }
};
export default withSitecoreContext()(FeaturedDetailHero);